<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
    </v-row>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO Será
          Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false">Ok</v-btn>
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0" height="80">
          <v-row class="d-flex justify-space-around align-center">
            <v-col cols="8" md="4">
              <v-text-field
                filled
                outlined
                rounded
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="4">
              <v-btn
                width="80%"
                color="#2ca01c"
                to="/asset/create"
                class="hidden-sm-and-down"
                dark
              >
                <v-icon>mdi-plus-thick</v-icon>
                Novo Ativo</v-btn
              >
              <v-btn dark color="#2ca01c" to="/asset/create" class="hidden-md-and-up">
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="products"
          :search="search"
          no-data-text="Sem dados no momento"
          :header-props="headerProps"
          no-results-text="Sem dados para sua busca"
          class="elevation-0"
        >
          <template v-slot:[`item.inventoryPrice`]="{ item }">
            <span>R$ {{ formatPrice(item.inventoryPrice) }}</span>
          </template>

          <template v-slot:[`item.inventory`]="{ item }">
            <span> {{ item.inventory }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn text small :to="`/asset/${item.id}/edit`">
              <v-icon small class="mr-2">mdi-pencil</v-icon></v-btn
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)">mdi-eraser</v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import PageHeader from "@/components/core/PageHeader.vue";
import InternalMenu from "@/components/core/InternalMenu.vue";
export default Vue.extend({
  name: "AssetsList",
  components: { Money, PageHeader, InternalMenu },
  data: () => ({
    valid: true,
    title: "Ativos",
    menuItems: [],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Ativos",
        disabled: true,
        href: "/assets",
      },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },

    deleteDialog: false,
    registerDialog: false,
    fullScreen: false,
    products: [],
    product: {
      name: "",
      description: "",
      inventory: 2,
      categoryId: "",
      inventoryPrice: 0.0,
      imageUrl: "",
      type: "PRODUTO",
      promotionalPrice: 0.0,
      costPrice: 0.0,
      ncm: "",
      brand: "",
      bardCode: "",
      manufacturer: "",
      model: "",
      unity: "UN",
      stockControl: true,
    },
    search: "",
    loading: false,
    productId: "",
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",

    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Tipo",
        value: "type",
        filtering: true,
      },
      {
        text: "Inventário",
        value: "inventory",
        filtering: true,
      },
      {
        text: "Preço Inventário R$",
        value: "inventoryPrice",
        filtering: true,
      },

      { text: "Ações", value: "actions", filtering: false, sortable: false },
    ],
    headerProps: {
      sortByText: "Ordenar por",
    },
  }),
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    openFileDialog() {
      document.getElementById("file-upload").click();
    },

    deleteOpen(itemId) {
      this.productId = itemId;
      this.deleteDialog = true;
    },
    getItems() {
      this.loading = true;
      http.get("assets").then(
        (data) => {
          this.products = data.data;
          this.loading = false;
        },
        (err) => {
          this.snackbar.color = "red";
          this.snackbar.text =
            "Hove um erro ao buscar os registros. Atualize a página por favor";
          this.snackbar.opened = true;
        }
      );
    },
    deleteItem() {
      http.delete(`assets/${this.productId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>
